<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/supervisors/permissions">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
            <form id="add-page-form" class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الاسم</label>
                    <input id="validationTooltip01" v-model="data.role_name" type="text" class="form-control"
                      placeholder="الاسم" :class="{
                        'is-invalid': submitform && $v.data.role_name.$error,
                      }" />
                    <div v-if="submitform && $v.data.role_name.$error" class="invalid-tooltip">
                      <span v-if="!$v.data.role_name.required">
                        الاسم مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الوصف</label>
                    <input id="validationTooltip01" v-model="data.role_description" type="text" class="form-control"
                      placeholder="الوصف" :class="{
                        'is-invalid': submitform && $v.data.role_description.$error,
                      }" />
                    <div v-if="submitform && $v.data.role_description.$error" class="invalid-tooltip">
                      <span v-if="!$v.data.role_description.required">
                        الوصف مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="dashboard"
                            style="display: unset !important;"></i>
                          لوحة التحكم
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="dashboard_select_all" data-id="dashboard"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.dashboard)" />
                        </div>
                      </div>
                      <div class="card-body p-3 dashboard" style="display: none;">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-check">
                              <input v-model="data.role_details.dashboard.dashboard" type="checkbox"
                                class="form-check-input" id="dashboard" />
                              <label class="form-check-label" for="dashboard"> لوحة التحكم </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="support"
                            style="display: unset !important;"></i>
                          الدعم الفني
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="support_select_all" data-id="support"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.support)" />
                        </div>
                      </div>
                      <div class="card-body p-3 support" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.support.support" type="checkbox" class="form-check-input"
                                id="support" />
                              <label class="form-check-label" for="support">الدعم الفني</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input v-model="data.role_details.support.customers" type="checkbox"
                                class="form-check-input" id="support_customers"
                                :disabled="data.role_details.support.support == false ? true : false" />
                              <label class="form-check-label" for="support_customers">محادثات العملاء</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input v-model="data.role_details.support.admins" type="checkbox" class="form-check-input"
                                id="support_admins"
                                :disabled="data.role_details.support.support == false ? true : false" />
                              <label class="form-check-label" for="support_admins">محادثات المشرفين</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="bookings"
                            style="display: unset !important;"></i>
                          الحجوزات
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="bookings_select_all" data-id="bookings"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.bookings)" />
                        </div>
                      </div>
                      <div class="card-body p-3 bookings" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.bookings.bookings" type="checkbox"
                                class="form-check-input" id="bookings" />
                              <label class="form-check-label" for="bookings">الحجوزات</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input v-model="data.role_details.bookings.flights" type="checkbox" class="form-check-input"
                                id="bookings_flights"
                                :disabled="data.role_details.bookings.bookings == false ? true : false" />
                              <label class="form-check-label" for="bookings_flights">حجوزات الطيران</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-check">
                              <input v-model="data.role_details.bookings.amadeus" type="checkbox" class="form-check-input"
                                id="bookings_amadeus"
                                :disabled="data.role_details.bookings.bookings == false ? true : false" />
                              <label class="form-check-label" for="bookings_amadeus">حجوزات أماديوس</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="pages"
                            style="display: unset !important;"></i>
                          الصفحات
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="pages_select_all" data-id="pages" type="checkbox"
                            :checked="areAllChildrenTrue(data.role_details.pages)" />
                        </div>
                      </div>
                      <div class="card-body p-3 pages" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.pages.pages" type="checkbox" class="form-check-input"
                                id="pages" />
                              <label class="form-check-label" for="pages">الصفحات</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="payments"
                            style="display: unset !important;"></i>
                          المدفوعات
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="payments_select_all" data-id="payments"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.payments)" />
                        </div>
                      </div>
                      <div class="card-body p-3 payments" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.payments.payments" type="checkbox"
                                class="form-check-input" id="payments" />
                              <label class="form-check-label" for="payments">المدفوعات</label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.payments.pending_request" type="checkbox"
                                class="form-check-input" id="payments_pending_request"
                                :disabled="data.role_details.payments.payments == false ? true : false" />
                              <label class="form-check-label" for="payments_pending_request">الطلبات المعلقة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.payments.transactions" type="checkbox"
                                class="form-check-input" id="payments_transactions"
                                :disabled="data.role_details.payments.payments == false ? true : false" />
                              <label class="form-check-label" for="payments_transactions">المعاملات
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.payments.wallet" type="checkbox" class="form-check-input"
                                id="payments_wallet"
                                :disabled="data.role_details.payments.payments == false ? true : false" />
                              <label class="form-check-label" for="payments_wallet">المحفظة
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="individual_users"
                            style="display: unset !important;"></i>
                          مستخدمين افراد
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="individual_select_all" data-id="individual_users"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.individual_users)" />
                        </div>
                      </div>
                      <div class="card-body p-3 individual_users" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.individual_users" type="checkbox"
                                class="form-check-input" id="individual_users" />
                              <label class="form-check-label" for="individual_users">مستخدمين افراد
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.add" type="checkbox"
                                class="form-check-input" id="individual_users_add"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_add">إضافة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.show" type="checkbox"
                                class="form-check-input" id="individual_users_show"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_show">عرض
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.edit" type="checkbox"
                                class="form-check-input" id="individual_users_edit"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_edit">تعديل
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.status" type="checkbox"
                                class="form-check-input" id="individual_users_status"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_status"> تغيير الحالة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.delete" type="checkbox"
                                class="form-check-input" id="individual_users_delete"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_delete">حذف
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.individual_users.login" type="checkbox"
                                class="form-check-input" id="individual_users_login"
                                :disabled="data.role_details.individual_users.individual_users == false ? true : false" />
                              <label class="form-check-label" for="individual_users_login">تسجيل الدخول
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="business_users"
                            style="display: unset !important;"></i>
                          مستخدمين أعمال
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="business_select_all" data-id="business_users"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.business_users)" />
                        </div>
                      </div>
                      <div class="card-body p-3 business_users" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.business_users" type="checkbox"
                                class="form-check-input" id="business_users" />
                              <label class="form-check-label" for="business_users"> مستخدمين أعمال
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.add" type="checkbox"
                                class="form-check-input" id="business_users_add"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_add">إضافة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.show" type="checkbox"
                                class="form-check-input" id="business_users_show"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_show">عرض
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.edit" type="checkbox"
                                class="form-check-input" id="business_users_edit"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_edit">تعديل
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.status" type="checkbox"
                                class="form-check-input" id="business_users_status"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_status"> تغيير الحالة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.delete" type="checkbox"
                                class="form-check-input" id="business_users_delete"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_delete">حذف
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.business_users.login" type="checkbox"
                                class="form-check-input" id="business_users_login"
                                :disabled="data.role_details.business_users.business_users == false ? true : false" />
                              <label class="form-check-label" for="business_users_login">تسجيل الدخول
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="pricing"
                            style="display: unset !important;"></i>
                          إعدادات الأسعار
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="pricing_select_all" data-id="pricing"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.pricing)" />
                        </div>
                      </div>
                      <div class="card-body p-3 pricing" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.pricing" type="checkbox" class="form-check-input"
                                id="pricing" />
                              <label class="form-check-label" for="pricing"> إعدادات الأسعار
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.markup.markup" type="checkbox"
                                class="form-check-input" id="pricing_markup"
                                :disabled="data.role_details.pricing.pricing == false ? true : false" />
                              <label class="form-check-label" for="pricing_markup">هوامش الربح
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.markup.add" type="checkbox"
                                class="form-check-input" id="pricing_markup_add"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.markup.markup == false) ? true : false" />
                              <label class="form-check-label" for="pricing_markup_add">
                                إضافة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.markup.edit" type="checkbox"
                                class="form-check-input" id="pricing_markup_edit"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.markup.markup == false) ? true : false" />
                              <label class="form-check-label" for="pricing_markup_edit">
                                تعديل
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.markup.status" type="checkbox"
                                class="form-check-input" id="pricing_markup_status"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.markup.markup == false) ? true : false" />
                              <label class="form-check-label" for="pricing_markup_status">
                                تعديل الحالة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.markup.delete" type="checkbox"
                                class="form-check-input" id="pricing_markup_delete"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.markup.markup == false) ? true : false" />
                              <label class="form-check-label" for="pricing_markup_delete">
                                حذف
                              </label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.coupons.coupons" type="checkbox"
                                class="form-check-input" id="pricing_markup"
                                :disabled="data.role_details.pricing.pricing == false ? true : false" />
                              <label class="form-check-label" for="pricing_coupons"> كوبونات الخصم
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.coupons.add" type="checkbox"
                                class="form-check-input" id="pricing_coupons_add"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.coupons.coupons == false) ? true : false" />
                              <label class="form-check-label" for="pricing_coupons_add">
                                إضافة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.coupons.edit" type="checkbox"
                                class="form-check-input" id="pricing_coupons_edit"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.coupons.coupons == false) ? true : false" />
                              <label class="form-check-label" for="pricing_coupons_edit">
                                تعديل
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.coupons.status" type="checkbox"
                                class="form-check-input" id="pricing_coupons_status"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.coupons.coupons == false) ? true : false" />
                              <label class="form-check-label" for="pricing_coupons_status">
                                تعديل الحالة
                              </label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.pricing.coupons.delete" type="checkbox"
                                class="form-check-input" id="pricing_coupons_delete"
                                :disabled="(data.role_details.pricing.pricing == false && data.role_details.pricing.coupons.coupons == false) ? true : false" />
                              <label class="form-check-label" for="pricing_coupons_delete">
                                حذف
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="permission-card card br-4">
                    <div class="accordion">
                      <div class="card-header br-4 row m-0 align-items-center">
                        <div class="col-md-10 no-padding-col">
                          <i class="las la-plus-square d-block open-permission" data-id="supervisors"
                            style="display: unset !important;"></i>
                          مشرفين الموقع
                        </div>
                        <div class="ripple-checkbox-primary col-md-2 no-padding-col text-right">
                          <input class="form-check-input select_all" id="supervisors_select_all" data-id="supervisors"
                            type="checkbox" :checked="areAllChildrenTrue(data.role_details.supervisors)" />
                        </div>
                      </div>
                      <div class="card-body p-3 supervisors" style="display: none;">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.supervisors" type="checkbox"
                                class="form-check-input" id="supervisors" />
                              <label class="form-check-label" for="supervisors">مشرفين الموقع</label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.admins.admins" type="checkbox"
                                class="form-check-input" id="supervisors_admins"
                                :disabled="data.role_details.supervisors.supervisors == false ? true : false" />
                              <label class="form-check-label" for="supervisors_admins">المستخدمين</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.admins.add" type="checkbox"
                                class="form-check-input" id="supervisors_admins_add"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.admins.admins == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_admins_add">إضافة</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.admins.edit" type="checkbox"
                                class="form-check-input" id="supervisors_admins_edit"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.admins.admins == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_admins_edit">تعديل</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.admins.status" type="checkbox"
                                class="form-check-input" id="supervisors_admins_status"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.admins.admins == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_admins_status">تعديل الحالة</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.admins.delete" type="checkbox"
                                class="form-check-input" id="supervisors_admins_delete"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.admins.admins == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_admins_delete">حذف</label>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <hr>
                          </div>
                          <div class="col-md-12">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.roles.roles" type="checkbox"
                                class="form-check-input" id="supervisors_roles"
                                :disabled="data.role_details.supervisors.supervisors == false ? true : false" />
                              <label class="form-check-label" for="supervisors_roles">الصلاحيات</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.roles.add" type="checkbox"
                                class="form-check-input" id="supervisors_roles_add"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.roles.roles == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_roles_add">إضافة</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.roles.edit" type="checkbox"
                                class="form-check-input" id="supervisors_roles_edit"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.roles.roles == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_roles_edit">تعديل</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.roles.status" type="checkbox"
                                class="form-check-input" id="supervisors_roles_status"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.roles.roles == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_roles_status">تعديل الحالة</label>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-check">
                              <input v-model="data.role_details.supervisors.roles.delete" type="checkbox"
                                class="form-check-input" id="supervisors_roles_delete"
                                :disabled="(data.role_details.supervisors.supervisors == false || data.role_details.supervisors.roles.roles == false) ? true : false" />
                              <label class="form-check-label" for="supervisors_roles_delete">حذف</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary float-right" type="submit">
                {{ $t('save') }}
                <i class="mdi mdi-content-save-outline"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import {
  required,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "permissions edit",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      title: "Add permission",
      data: {
        role_name: '',
        role_description: '',
        role_details: {
          dashboard: {
            dashboard: false,
          },
          support: {
            support: false,
            customers: false,
            admins: false
          },
          bookings: {
            bookings: false,
            flights: false,
            amadeus: false
          },
          pages: {
            pages: false,
          },
          payments: {
            payments: false,
            pending_request: false,
            transactions: false,
            wallet: false,
          },
          individual_users: {
            individual_users: false,
            add: false,
            show: false,
            edit: false,
            delete: false,
            login: false,
            status: false,
          },
          business_users: {
            business_users: false,
            add: false,
            show: false,
            edit: false,
            delete: false,
            login: false,
            status: false,
          },
          pricing: {
            pricing: false,
            markup: {
              markup: false,
              add: false,
              edit: false,
              status: false,
              delete: false,
            },
            coupons: {
              coupons: false,
              add: false,
              edit: false,
              status: false,
              delete: false,
            }
          },
          supervisors: {
            supervisors: false,
            admins: {
              admins: false,
              add: false,
              edit: false,
              status: false,
              delete: false
            },
            roles: {
              roles: false,
              add: false,
              edit: false,
              status: false,
              delete: false
            }
          }
        }
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    data: {
      role_name: { required },
      role_description: { required }
    },
  },
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    setAllValuesToTrue(obj, val) {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          this.setAllValuesToTrue(obj[key], val);
        } else {
          obj[key] = val;
        }
      }
    },
    areAllChildrenTrue(obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          if (!this.areAllChildrenTrue(obj[key])) {
            return false;
          }
        } else if (obj[key] !== true) {
          return false;
        }
      }
      return true;
    },
    Jquery() {
      $(document).on('submit', '#add-page-form', function () {
        if (!that.$v.$anyError) {
          $(this).find(":submit").attr('disabled', 'disabled');
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          };
          var url = `${that.$appUrl}/api/admin/admin-roles/update/${that.$route.params.id}`;
          that.axios.post(url, that.data, { headers })
            .then(response => {
              if (response.data.status == 1) {
                that.$toast.open({
                  message: response.data.message,
                  type: 'success',
                  position: 'top-left',
                  duration: 2500
                });
                setTimeout(() => {
                  that.$router.push(`/supervisors/permissions`);
                }, 1000);
              } else {
                $(this).find(":submit").prop('disabled', false);
                that.$toast.open({
                  message: response.data.message,
                  type: 'error',
                  position: 'top-left',
                  duration: 2500
                });
              }
            });
        }
      });
      $(document).on('click', '.open-permission', function () {
        var id = $(this).data('id');
        if ($(this).hasClass("la-plus-square")) {
          $('.la-minus-square').each(function (i, obj) {
            $(this).click();
          });
          $('.' + id).show();
          $(this).removeClass("la-plus-square");
          $(this).addClass('la-minus-square');
        } else if ($(this).hasClass("la-minus-square")) {
          $('.' + id).hide();
          $(this).removeClass("la-minus-square");
          $(this).addClass('la-plus-square');
        }
      });
      $(document).on('click', '.select_all', function () {
        var id = $(this).data('id');
        if ($(this).is(":checked")) {
          that.setAllValuesToTrue(that.data.role_details[id], true);
        } else {
          that.setAllValuesToTrue(that.data.role_details[id], false);
        }
      });
      $(document).on('click', '.card-body .form-check-input', function () {
        var id = $(this).attr('id');
        if (!$(this).is(":checked")) {
          that.setAllValuesToTrue(that.data.role_details[id], false);
          $(this).attr("checked", null);
        }
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    $('.page-loader-parent').show();
    $('#add-page-form').hide();
    var url = `${this.$appUrl}/api/admin/admin-roles/edit/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.data = {
        role_name: response.data.role.role_name,
        role_description: response.data.role.role_description,
        role_details: response.data.role.role_details
      };
      $('.page-loader-parent').remove();
      $('#add-page-form').show();
    });
  }
};
</script>
<style lang="scss" scoped>
input[type="number"],
input[type="date"],
input[type="text"] {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0;
  min-height: 2.375rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.input-group-text {
  border-radius: 0 !important;
  min-height: 2.375rem;
}


.no-padding-col {
  padding: 0;
}

.br-4 {
  border-radius: 4px !important;
}

.permission-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 25px;
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 5px;
  margin-bottom: 30px;

  .la-plus-square,
  .la-minus-square {
    vertical-align: middle;
    font-size: 30px;
    padding: 0px 0px 0px 10px;
    color: #4f9681;
    background: transparent;
    cursor: pointer;
    display: none !important;
  }

  .ripple-checkbox-primary {
    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin: 0;
    }
  }

  .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
  }

  .card-header .col-md-10 {
    color: #000;
  }
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}
</style>